import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import cache from './plugins/cache.js'
import VueGtag from "vue-gtag";
import commonComponents  from './components/Globals/loader';
commonComponents.forEach(component => {
  Vue.component(component.name, component);
}); 

Vue.config.productionTip = false
const methods = {
  sendRequest: function(type='get',url,data=null){
    return new Promise((resolve, reject)=>{
      axios.defaults.baseURL = url ? url : '//localhost:3000'
      axios.defaults.headers['x-api-key'] = process.env.VUE_APP_API_KEY ? process.env.VUE_APP_API_KEY : null
      switch(type){
        default:
          axios.get(url,data).then((response)=>{
            resolve(response)
          },(response)=>{
            console.error({
              type,
              data,
              response
            });
            reject()
          })
        break;
        
        case"post":
          axios.post(url,data).then((response)=>{
            resolve(response)
          },(error)=>{
            switch(error.response.status){
              case 400:
                alert('Error')
                resolve(error.response)
              break;
            }
            console.error({
              type,
              data,
              error
            });
            reject()
          })
        break;
        
        case"downloadResponse":
        console.log('downloadResponse: downloadResponse',{url,data})
          axios.post(url,data,{responseType:'arraybuffer'}).then((response)=>{
            let type = response.headers["content-type"]
            let blob = new Blob([response.data], {type})
            let fileURL = window.URL.createObjectURL(blob)
            let filename = "unknown.pdf"
            let disposition = "attachment; filename=report.pdf"//response.headers["content-disposition"]
         
            if (disposition && disposition.indexOf("attachment") !== -1) {
               let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                  matches = filenameRegex.exec(disposition);
         
               if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
               }
            }

         
            let link = document.createElement("a");
            
            
            if (typeof link.download === "undefined") {
               window.location.href = fileURL;
            } else {
               link.href = fileURL;
               link.download = filename;
              //  document.body.appendChild(link);
               link.click();
            }
          },(error)=>{
            switch(error.response.status){
              case 400:
                alert('Error')
                resolve(error.response)
              break;
            }
            console.error({
              type,
              data,
              error
            });
            reject()
          })
        break;
      }
    });
  }
}
Vue.config.productionTip = false
Vue.mixin({
  methods: methods
});

Vue.use(VueGtag, {
  config: { id: "G-RTKHZFLJ2R" },
  appName: 'Decision Aid - mcSPC',
  pageTrackerScreenviewEnabled: true
},router);

let code = new Vue({
  created: function(){
    Vue.prototype.cache = cache
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

if(location.hostname=='localhost'){
  window.code = code;
}else{
  code = null;
}