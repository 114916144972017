<template>
  <div v-show="$store.getters.env=='local'">
    <v-btn text @click="$router.push('/surveys/vte')">VTE</v-btn>
    <v-btn text @click="$router.push('/surveys/cua')" data-test-cua>CUA</v-btn>
    <v-btn text @click="$router.push('/test/algorithm')">Test Algorithm</v-btn>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    mounted: function(){
      if(this.$route.name=='Home' || this.$route.matched.length==0){        
        let output = '/tools/mcspc'

        let counter = 0
        for(let key in this.$route.query){
          let value = this.$route.query[key]
          if(counter==0){
            output+='?'
          }else{
            output+='&'
          }
          output+=key+'='+value
        }
        this.$router.push(output)
      }
    }
  }
</script>
