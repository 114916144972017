<template>
    <v-select            
        color="blue"
        light
        dense
        outlined
        v-model="lang"
        :items="items"
        label="Language"/>  
</template>

<script>
export default {
    name: "Language",
    created: function(){
        this.init()
    },
    data: function(){
        return {
            lang: null
        }
    },
    methods: {
        init: function(){
            let queryLanguage = this.$route.query.language ? this.$route.query.language : false
            let cookieLanguage = this.cache.storage.local.get('language')
            let browserLanguage = navigator.userLanguage || navigator.language;
            let browserLangPrimaryCode = browserLanguage.substring( 0, 2 );
            

            this.lang = queryLanguage ? queryLanguage : cookieLanguage ? cookieLanguage : browserLangPrimaryCode && this.returnMap(this.items,'value') ? browserLangPrimaryCode : this.language
        },
        returnMap: function(array, index){
            let output = {}

            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item[index]] = item
            }


            return output
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        items: function(){
            return [
                {text:'EN',value:'en'},
                {text:'FR',value:'fr'}
            ]
        }
    },
    watch: {
        language: function(){
            this.lang = this.$store.getters.language
        },
        lang: function(){
            this.cache.storage.local.set('language',this.lang)
            this.$store.commit('language',this.lang)
        }
    }
}
</script>

<style>

</style>